
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.contact-home,
[class*='contact-home--'] {
  max-height: 85vh;
  padding: $spacing * 3 $spacing * 2;
  color: $c-promo-card;
  background: $white;
  border-radius: 1rem;

  &.generic-popup {
    display: block;
    overflow: auto;
    max-width: 72rem;
  }

  @include mq(m) {
    width: 72rem;
    padding: $spacing * 3.5 $spacing * 3.5;
  }
}

.contact-home__title {
  display: flex;
  align-items: center;
  margin-top: $spacing * 0.75;

  span {
    @include mq($until: m) {
      margin-bottom: $spacing;
    }

    @include mq(m) {
      margin-left: $spacing;
    }
  }

  @include mq($until: m) {
    flex-direction: column-reverse;
    align-items: baseline;
  }
}

.contact-home__content {
  margin-top: $spacing * 0.5;
}

.contact-home__content__value {
  font-weight: 600;
}

.contact-home__phone {
  margin-top: $spacing * 2.5;
}

.contact-home__phone__link {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  font-family: $ff-alt;
  font-size: 2.4rem;
  font-weight: 600;
  text-decoration: none;

  .icon {
    max-height: 5rem;
  }

  &.is-closed {
    color: $c-gray-lightest;
    pointer-events: none;
  }

  &:hover {
    .contact-home__phone__link__label::after {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  @include mq(m) {
    font-size: 3.6rem;
  }
}

.contact-home__phone__link__label {
  position: relative;
  line-height: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    display: block;
    width: 100%;
    height: 0.2rem;
    background: $c-blue-dark;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
}

.contact-home__phone__icon {
  fill: $c-green-light;

  &.is-busy {
    fill: $c-orange;
  }

  &.is-closed {
    fill: $gray-light;
  }
}

.contact-home__links {
  display: flex;
  margin-top: $spacing * 2.5;
  gap: 2rem;
}
